@import "colors";
@import "mixins";

html {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;

  margin: 0;
  padding: 0;


  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.25;

  color: rgb(0, 0, 0);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
button,
input,
select,
textarea {
  font-family: Lato, sans-serif;
}

button,
input,
select,
textarea {
  color:inherit;
  font:inherit;
  margin:0
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
